<template>
    <div
      id="userOperationHistory"
      ref="userOperationHistory"
      v-loading="loading"
      :element-loading-text="$t('UserSettings.a1')"
    >
        <div class="interface">
            <div class="pageTitle"> 
                <div class="pageTitle-left">
                <img src="../../../public/images/set/setindex05.png" />
                <!-- 操作记录 -->
                <span>{{$t('userOperationHistory.a1')}}</span>
                </div>
                <div>
                <a-icon type="close" :style="{ color: 'rgba(12, 12, 12, 0.65)' }" @click="Fallback"/>
                </div>
            </div>
        </div>
        <div class="header">
        <!-- 页面标题 -->
        <!-- <my-headertitle></my-headertitle> -->
        <!-- 查询栏 -->
        <div class="query">
            <a-row :gutter="[16,16]">
            
                <!-- usersName,loginName,serviceText,screenName,operation,equipmentName,signalName -->
            <a-col :span="4">
                <!-- 用户名称 -->
                <a-input v-model="usersName" style="width:100%" :placeholder="$t('userOperationHistory.a2')" >
                
                </a-input>
            </a-col>
            
            <a-col :span="4">
                <!-- 登录ID -->
                <a-input v-model="loginName" style="width:100%" :placeholder="$t('userOperationHistory.a3')" >
                
                </a-input>
            </a-col>
            
            <a-col :span="4">
                <!-- 服务 -->
                <a-input v-model="serviceText" style="width:100%" :placeholder="$t('userOperationHistory.a4')" >
                
                </a-input>
            </a-col>
            
            <a-col :span="4">
                <!-- 画面名称 -->
                <a-input v-model="screenName" style="width:100%" :placeholder="$t('userOperationHistory.a5')" >
                
                </a-input>
            </a-col>
            <a-col :span="4">
                <!-- 操作内容 -->
                <a-input v-model="operation" style="width:100%" :placeholder="$t('userOperationHistory.a6')" >
                
                </a-input>
            </a-col>
            <a-col :span="4">
                <!-- 设备名称 -->
                <a-input v-model="equipmentName" style="width:100%" :placeholder="$t('userOperationHistory.a7')" >
                
                </a-input>
            </a-col>
            <a-col :span="4">
                <!-- 信号名称 -->
                <a-input v-model="signalName" style="width:100%" :placeholder="$t('userOperationHistory.a8')" >
                
                </a-input>
            </a-col>
            
            
            <!-- 时间 -->
            <a-col :span="10">
                <a-input-group compact>
                    <!-- <div style="width:30%">
                        发生日期
                    </div> -->

                <!-- 初始日期 -->
                <div style="width:30%">
                    <a-date-picker v-model="startDate" show-time :allowClear="false" format="YYYY-MM-DD HH:mm:ss" @change="handleCalendarChange" style="width:100%"/>
                </div>
                <!-- 过渡线 -->
                <div style="width:5%">
                    <a-input style=" pointer-events: none; backgroundColor: #fff;text-align: center" placeholder="~"/>
                </div>
                <!-- 终止日期 -->
                <div style="width:30%">
                    <a-date-picker  v-model="endDate" :disabled-date="disabledEndDate" show-time :allowClear="false" format="YYYY-MM-DD HH:mm:ss" style="width:100%"/>
                </div>
                </a-input-group>
            </a-col>
            <a-col :span="4">
            </a-col>
            <a-col :span="4">
            </a-col>
            <!-- 查询按钮 -->
            <a-col :span="2">
                <a-button type="primary" @click="loadData">{{ $t("visitorBookingRecord.a8")}}</a-button>
            </a-col>
            </a-row>
        </div>
        <!-- 表格区域 -->
        </div>
        <div class="main" ref="main">
            <a-table :columns="columns" :dataSource="data" :pagination="pagination" :scroll="size" :rowKey="(record) => record.key">
                <template slot="screenName" slot-scope="text,record">
                            <!--  -->
                <div v-if="text.indexOf('（详细）')>0 || text.indexOf('(详情)')>0">
                    <a @click="openDetail(record)"> {{ text }}</a>
                </div>
                <div v-else>
                    {{ text }}
                </div>
                </template>        
            </a-table>
        </div>
        <a-modal v-model="visible" :title="detail.title" :width='1200'  :footer="null" >
            <a-descriptions title="">
                <!-- 用户名称 -->
                <a-descriptions-item :label="$t('userOperationHistory.a2')">
                    {{ detail.usersName }}
                </a-descriptions-item>
                <!-- 登录ID -->
                <a-descriptions-item :label="$t('userOperationHistory.a3')" :span="2">
                    {{ detail.loginName }}
                </a-descriptions-item>
                <!-- 操作类型 -->
                <a-descriptions-item :label="$t('userOperationHistory.a10')">
                    {{ detail.action }}
                </a-descriptions-item>
                <!-- 操作时间 -->
                <a-descriptions-item :label="$t('userOperationHistory.a11')" :span="2">
                    {{ detail.dateTime }}
                </a-descriptions-item>
                <!-- 操作详情 -->
                <a-descriptions-item :label="$t('userOperationHistory.a12')" :span="3">
                    <a-table :columns="columns2" :dataSource="detail.items" :pagination="false" :scroll="size2"  :rowKey="(record) => record.key">
                        <template slot="old" slot-scope="text,record">
                            <!--  -->
                            <div v-if="record.olds">
                                <div
                                    v-for="(tag,index) in record.olds"
                                    :key="index"
                                >
                                    {{ tag}}
                                </div>
                            </div>
                            <div v-else>
                                {{ text }}
                            </div>
                        </template>   
                        <template slot="new" slot-scope="text,record">
                            <!--  -->
                            <div v-if="record.news">
                                <div
                                    v-for="(tag,index) in record.news"
                                    :key="index"
                                >
                                    {{ tag}}
                                </div>
                            </div>
                            <div v-else>
                                {{ text }}
                            </div>
                        </template>        
                    </a-table>
                </a-descriptions-item>
              
            </a-descriptions>
            <!-- <div>
                <pre>{{ detail }}</pre>
            </div> -->
        </a-modal>
    </div>
</template>
<script>
import headerTitle from "../../components/Title/headerTitle"
import { getUserOperationHistoryData} from "../../api/sysmanage";
import { tableSort } from "../../utils/utils"
import moment from "moment";
export default {
    data(){
        return {
            loading:false,
            startDate: new moment().startOf('day'),  
            endDate: new moment().endOf('day'),  
            usersName:'',
            loginName:'',
            serviceText:'',
            screenName:'',
            operation:'',
            equipmentName:'',
            signalName :'',
            data:[],
            size: { x: 1200,y: 240 },
            size2: {y: 420 },
            pagination: {
                total: 0, //数据总数
                pageSize: 100, //每页中显示10条数据
                showTotal: (total) => `${total} ${this.$t("visitorBookingRecord.a11")}`, //分页中显示总的数据
                showQuickJumper: true, //是否可以快速跳转至某页
                defaultCurrent: 1, //默认当前页面数
                hideOnSinglePage: false, //只有一页时是否隐藏分页：默认值false
                onShowSizeChange: (current, pageSize) => {
                this.pagination.defaultCurrent = 1;
                this.pagination.pageSize = pageSize;
                },
                // 改变每页数量时更新显示
                onChange: (current, size) => {
                this.pagination.defaultCurrent = current;
                this.pagination.pageSize = size;
                },
            },
            columns2: [
                {
                    title: this.$t('userOperationHistory.a13'),//'操作项',
                    dataIndex: "title",
                    width: 150,
                // ellipsis: true,
                }, {
                    title: this.$t('userOperationHistory.a14'),//'原数据',
                    dataIndex: "old",
                    scopedSlots: { customRender: "old" },
                    width: 150,
                // ellipsis: true,
                }, {
                title: this.$t('userOperationHistory.a15'),//'新数据',
                    dataIndex: "new",
                    scopedSlots: { customRender: "new" },
                    width: 150,
                // ellipsis: true,
                },
            ],
            columns: [
                {
                    title: "NO",
                    customRender: (text, record, index) =>
                        `${
                        (this.pagination.defaultCurrent - 1) * this.pagination.pageSize +
                        index +
                        1
                        }`,
                    width: 60,
                },
                {
                title: this.$t('userOperationHistory.a9'),//'发生日期',
                    dataIndex: "dateTime",
                    sorter: (a, b) => {
                        return tableSort(a, b, "visitorName");
                    },
                    
                    width: 150,
                // ellipsis: true,
                },
                {
                    title: this.$t('userOperationHistory.a2'),//'用户名称',
                    dataIndex: "usersName",
                    sorter: (a, b) => {
                        return tableSort(a, b, "usersName");
                    },
                    
                    width: 200,
                // ellipsis: true,
                },,
                {
                    title: this.$t('userOperationHistory.a3'),//'登录ID',
                    dataIndex: "loginName",
                    sorter: (a, b) => {
                        return tableSort(a, b, "loginName");
                    },
                    
                    width: 150,
                // ellipsis: true,
                },
                {
                    title: this.$t('userOperationHistory.a4'),//'服务',
                    dataIndex: "serviceText",
                    sorter: (a, b) => {
                        return tableSort(a, b, "serviceText");
                    },
                    
                    width: 150,
                // ellipsis: true,
                },
                {
                    title: this.$t('userOperationHistory.a5'),//'画面名称',
                    dataIndex: "screenName",
                    scopedSlots: { customRender: "screenName" },
                    sorter: (a, b) => {
                        return tableSort(a, b, "screenName");
                    },
                    width: 200,
                // ellipsis: true,
                },
                {
                    title:this.$t('userOperationHistory.a6'),//'操作内容',
                    dataIndex: "operation",
                    sorter: (a, b) => {
                        return tableSort(a, b, "operation");
                    },
                    // width: 200,
                    ellipsis: true,
                },

                // {
                //     title: '操作结果',
                //     dataIndex: "result",
                
                //     sorter: (a, b) => {
                //         return tableSort(a, b, "result");
                //     },
                //     width: 100,
                // },
                {
                    title: this.$t('userOperationHistory.a7'),//'设备名称',
                    dataIndex: "equipmentName",
                  
                    sorter: (a, b) => {
                        return tableSort(a, b, "equipmentName");
                    },
                    width: 180,
                    // ellipsis: true,
                },
                {
                    title: this.$t('userOperationHistory.a8'),//'信号名称',
                    dataIndex: "signalName",
                    sorter: (a, b) => {
                        return tableSort(a, b, "signalName");
                    },
                    width: 180,
                    // ellipsis: true,
                },
                
                // {
                //     title: this.$t("throughrecords.a27"),
                //     dataIndex: "operate",
                //     scopedSlots: { customRender: "operate" },
                //     width: 150,
                //     fixed: "right",
                // },
            ],
            visible:false,
            detail:{
                title:'',
                items:[],
                action:'',
            },
        }
    },
    mounted(){
        this.loadData();
        this.tableSize()
        window.addEventListener("resize", this.tableSize)
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.tableSize)
  },
    components:{
        "my-headertitle": headerTitle,
    },
    methods:{
        openDetail(e) {
            // console.log("handleOk e:",e)
            this.visible = true;
            this.detail = e;
            let arr = e.operation.split(',');
            let action = arr[0].replace("：","");
            let items = [];

            for(let i =1;i<arr.length;i++){

                let cc = arr[i].split('_');
                let item ={title:cc[0],old:cc[1],new:cc[2],key:i};

                if(item.title.indexOf('关联建筑')>=0){
                    //关联建筑
                    let ositeVals = item.old.split('\r\n');
                    item.olds = ositeVals;
                    //关联建筑
                    let nsiteVals = item.new.split('\r\n');
                    item.news = nsiteVals;
                    // console.log('item',item);
                } else if(item.title.indexOf('关连建筑')>=0){
                    //关联建筑
                    let ositeVal = item.old.replace(/(\n|\r|\r\n)/g,'@##@')
                    let ositeVals = ositeVal.split('@##@');
                    item.olds = ositeVals;
                    //关联建筑
                     let nsiteVal = item.new.replace(/(\n|\r|\r\n)/g,'@##@')
                    let nsiteVals = nsiteVal.split('@##@');
                    item.news = nsiteVals;
                }
                // console.log('item',item.title.indexOf('关连建筑'),item);
                
                items.push(item);
            }

            this.detail= {
                title:e.screenName,
                items:items,
                action:action,
                dateTime: e.dateTime,
                loginName:e.loginName,
                usersName:e.usersName,
            }
        },
        // 初始日期/时间选择变化回调
        handleCalendarChange(value) {
            if(value){ 
                this.endDate = moment(value._d).endOf("day")
            }
        },
        // 终止日期/时间的日期不可选范围
        disabledEndDate(endValue) {
            const startValue = this.startDate;
            if (!endValue || !startValue) {
                return false
            }
            return ( startValue.valueOf() >= endValue.valueOf() || endValue >= moment(new Date(startValue)).add(1, "months") )
        },
        // 表格大小调节
        tableSize() {
            let height = this.$refs.main.clientHeight
            let tableHeight = height - 120
            this.size.y = tableHeight
        },
        loadData(){
            let param = {
                startDate:this.startDate.format('YYYY-MM-DD HH:mm:ss'),
                endDate:this.endDate.format('YYYY-MM-DD HH:mm:ss'),
                usersName:this.usersName,
                loginName:this.loginName,
                serviceText:this.serviceText,
                screenName:this.screenName,
                operation:this.operation,
                equipmentName:this.equipmentName,
                signalName :this.signalName,
            }
            this.loading = true;
            getUserOperationHistoryData(param)
            .then((res)=>{
                // console.log('getUserOperationHistoryData',res);
                this.loading = false;
                this.data = res.data.histories;
            })
            .catch((err)=>{
                this.loading = false;
            });
        },
        Fallback(){
            this.$router.go(-1);
        },
    }
}
</script>
<style scoped>
#userOperationHistory{
    width: 100%;
    height: 100%;
    box-shadow: 0px 0px 10px #bdbcbc;
    position: relative; 
    background-color: #fff;
}
.interface {
  width: 100%;
  padding: 10px 20px;
  background-color: #ffffff;
  overflow: hidden;
}
.pageTitle{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  border-bottom: 1px solid rgba(12, 12, 12, 0.2);
}
.pageTitle-left{
  display: flex;
  align-items: center;
}
.pageTitle img{
  width: 45px;
  margin-right: 10px;
}
.pageTitle span{
    margin-top: -5px;
}
.header {
  min-width: 850px;
  height: 120px;
  padding: 0 20px;
  overflow: hidden;
}
.query{
  margin: 8px 0px;
}
.main {
  height: calc(100% - 161px);
  width: 100%;
  min-width: 670px;
  padding: 0px 20px;
  overflow: hidden;
}

</style>